import Logo from "../assets/images/logo.svg";
import DefaultLogo from "../assets/images/default_logo.svg";
import FullLogo from "../assets/images/full_logo.svg";
import FullDisableLogo from "../assets/images/full_disable_logo.svg";
import FullWhiteLogo from "../assets/images/full_white_logo.svg";
import PatternImage from "../assets/images/pattern.png";
import MacBookImage from "../assets/images/macbook.png";
import AuthSidebarImageSvg from "../assets/images/auth_sidebar.svg";
import UserPurpleImage from "../assets/images/user_purple.png";
import UserSharedLineImage from "../assets/images/user_shared_line.png";
import MailCheckLineImage from "../assets/images/mail_check_line.png";
import ResizeIcon from "../assets/images/resize.png";
import EmptyStateIcon from "../assets/images/empty_state.png";
import JobNoPosts from "../assets/images/job_no_post.png";
import SliderMarkIcon from "../assets/images/slider_mark.svg";
import SliderThumbMarkIcon from "../assets/images/slider_thumb_mark.svg";
import AvatarIcon from "../assets/images/avatar.svg";
import SettingIcon from "../assets/images/settings.svg";
import UploadCloudIcon from "../assets/images/upload-cloud.svg";
import NextStageIcon from "../assets/images/next-stage.svg";
import SparkleIcon from "../assets/images/sparkle.svg";
import UploadCompanyIcon from "../assets/images/upload_company.svg";
import VoiceCallCompletedIcon from "../assets/images/voice_call_completed.png";
import VoiceCallExpiredIcon from "../assets/images/voice_call_expired.png";
import VoiceCallRejectedIcon from "../assets/images/voice_call_rejected.png";
import VoiceCallBackgroundLine from "../assets/images/voice_call_bg_line.svg";
import MagicFillIcon from "../assets/images/magic_fill.svg";
import LinkedInCoverImage from "../assets/images/linkedin_cover.png";
import PositionFilledImage from "../assets/images/position_filled.png";
import ApplicationSubmittedImage from "../assets/images/application_submitted.png";
import ApplyTopVector from "../assets/images/apply_top_vector.svg";
import ApplyBottomVector from "../assets/images/apply_bottom_vector.svg";
import InviteDialogVector from "../assets/images/invite_dialog_vector.svg";
import WelcomeBgVector from "../assets/images/welcome_bg_vector.svg";
import OnBoardDomainVector from "../assets/images/on_board_domain_bg_vector.svg";
import BlueSparkleIcon from "../assets/images/blue_sparkle.svg";
import GoogleCalendarIcon from "../assets/images/google_calendar.png";
import AppleCalendarIcon from "../assets/images/apple_calendar.png";
import OutlookCalendarIcon from "../assets/images/outlook_calendar.png";
import NotionCalendarIcon from "../assets/images/notion_calendar.png";
import AumaDesktopIcon from "../assets/images/auma_desktop.png";
import DefaultProfileIcon from "../assets/images/profile.svg";
import VerifiedTickIcon from "../assets/images/verified_tick.svg";
import DemoLockImage from "../assets/images/demo_lock.png";
import JobLeftVector from "../assets/images/job_left_vector.svg";
import JobRightVector from "../assets/images/job_right_vector.svg";
import JobCardImage from "../assets/images/job_card.png";
import JobInviteVector from "../assets/images/job_invite_vector.svg";
import { ReactComponent as PointArrowIcon } from "../assets/images/point_arrow.svg";
import NoCancelInterview from "../assets/images/no_cancel_interview.png";
import NoCancelInterviewVector from "../assets/images/no_cancel_interview_vector.svg";
import NoPastInterview from "../assets/images/no_past_interview.png";
import NoPastInterviewVector from "../assets/images/no_past_interview_vector.svg";
import NoUpcomingInterview from "../assets/images/no_upcoming_interview.png";
import NoUpcomingInterviewVector from "../assets/images/no_upcoming_interview_vector.svg";
import InterviewCardVector from "../assets/images/interview_card_vector.svg";

export {
  InterviewCardVector,
  NoCancelInterview,
  NoCancelInterviewVector,
  NoPastInterview,
  NoPastInterviewVector,
  NoUpcomingInterview,
  NoUpcomingInterviewVector,
  JobInviteVector,
  PointArrowIcon,
  JobCardImage,
  JobLeftVector,
  JobRightVector,
  DemoLockImage,
  VerifiedTickIcon,
  DefaultProfileIcon,
  AumaDesktopIcon,
  GoogleCalendarIcon,
  AppleCalendarIcon,
  OutlookCalendarIcon,
  NotionCalendarIcon,
  BlueSparkleIcon,
  OnBoardDomainVector,
  WelcomeBgVector,
  InviteDialogVector,
  ApplyTopVector,
  ApplyBottomVector,
  ApplicationSubmittedImage,
  PositionFilledImage,
  LinkedInCoverImage,
  SparkleIcon,
  NextStageIcon,
  UploadCloudIcon,
  SettingIcon,
  AvatarIcon,
  SliderThumbMarkIcon,
  SliderMarkIcon,
  EmptyStateIcon,
  JobNoPosts,
  PatternImage,
  MacBookImage,
  UserPurpleImage,
  UserSharedLineImage,
  MailCheckLineImage,
  ResizeIcon,
  Logo,
  DefaultLogo,
  FullLogo,
  FullDisableLogo,
  FullWhiteLogo,
  AuthSidebarImageSvg,
  UploadCompanyIcon,
  VoiceCallCompletedIcon,
  VoiceCallExpiredIcon,
  VoiceCallRejectedIcon,
  VoiceCallBackgroundLine,
  MagicFillIcon,
};
