import React, { useEffect, useState } from "react";

import { Box, Dialog, DialogActions, DialogContent, IconButton, styled, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";

import Drawer from "./Drawer";
import Header from "./Header";
import DemoBanner from "../../components/Banner/DemoBanner";
import Button from "../../components/ui/Button";

import { dispatch, useSelector } from "../../store";
import { openDrawer } from "../../store/slices/menu";
import { getCompanyDetailService } from "../../services/company";
import { getUserService, joinWorkspaceService } from "../../services/authentication";

import { AumaDesktopIcon, DemoLockImage, FullLogo, InviteDialogVector } from "../../utils/images";

import { useUserRole } from "../../hooks/useUserRole";

import { RiCloseLine } from "react-icons/ri";

export const StyledCompanyBadge = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(6),

  "& img": {
    width: "25px",
    height: "25px",
    borderRadius: "4px",
  },
}));

const StyledMobileContent = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  textAlign: "center",
  padding: "0 55px",
}));

const MainLayout = () => {
  const { id } = useParams();

  const theme = useTheme();
  const navigation = useNavigate();

  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { drawerOpen } = useSelector((state) => state.menu);
  const { company } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.user);

  const [open, setOpen] = useState(drawerOpen);

  const [isJoinDialogOpen, setIsJoinDialogOpen] = useState(false);
  const [joinDialogData, setJoinDialogData] = useState(null);

  const role = useUserRole();
  const isDemoAccount = role === "demo";
  const [isDemoAccountDialogOpen, setIsDemoAccountDialogOpen] = useState(isDemoAccount);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    if (matchDownMD || !open) {
      dispatch(openDrawer({ drawerOpen: true }));
      setOpen(true);
    }

    setIsDemoAccountDialogOpen(isDemoAccount);
  }, [matchDownMD, isDemoAccount]);

  useEffect(() => {
    if (id && !company) {
      dispatch(getCompanyDetailService(id));
    }
  }, [id]);

  useEffect(() => {
    if (user) {
      if (user.invitationList && user.invitationList.length > 0) {
        setIsJoinDialogOpen(true);
        setJoinDialogData(user.invitationList[0]);
      } else {
        setIsJoinDialogOpen(false);
      }
    }
  }, [user]);

  const handleJoinCancelWorkspace = (status) => {
    const body = {
      inviteeEmail: user?.email,
      companyId: joinDialogData?.company_id,
      inviterId: joinDialogData?.created_by,
      status: status,
    };

    setIsJoinDialogOpen(false);
    dispatch(joinWorkspaceService(body)).then(() => {
      setJoinDialogData(null);
      dispatch(getUserService(user?._id));
      dispatch(getCompanyDetailService(id));

      if (status === "accepted") {
        navigation(`/jobs/${id}`);
      }
    });
  };

  return (
    <>
      {isDemoAccount && <DemoBanner />}

      {!matchDownSM && (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Header isDemoAccount={isDemoAccount} open={open} handleDrawerToggle={handleDrawerToggle} />
          <Drawer isDemoAccount={isDemoAccount} open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box
            component="main"
            sx={{
              width: "100%",
              flexGrow: 1,
              p: { xs: "0 8px", sm: "0 12px" },
              overflow: "auto",
            }}
          >
            <Toolbar sx={{ minHeight: "88px", marginTop: isDemoAccount ? "48px" : 0, paddingTop: 0, paddingBottom: 0 }} />
            <Box sx={{ paddingLeft: "32px", paddingRight: "32px" }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      )}

      {matchDownSM && (
        <Box sx={{ position: "relative", width: "100%", height: "100%", minHeight: "100vh" }}>
          <img src={FullLogo} alt="Auma Logo" style={{ top: "50px", position: "absolute", left: "50%", transform: "translateX(-50%)" }} />
          <StyledMobileContent>
            <img src={AumaDesktopIcon} alt="Auma Desktop" style={{ width: "100%", height: "100%" }} />
            <Typography variant="labelLG">Best on Desktop</Typography>
            <Typography variant="paragraphSM">
              Please access our app from a desktop computer to enjoy all features and optimal performance.
            </Typography>
          </StyledMobileContent>
          <Typography variant="paragraphXS" sx={{ position: "absolute", bottom: "24px", width: "100%", textAlign: "center" }}>
            © 2024 Auma
          </Typography>
        </Box>
      )}

      <Dialog
        sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: "600px" } }}
        fullWidth
        maxWidth="sm"
        open={isJoinDialogOpen}
        className="confirm-dialog"
      >
        <img src={InviteDialogVector} alt="" style={{ zIndex: 0, position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} />
        <DialogContent sx={{ borderBottom: "0 !important", zIndex: 2 }}>
          <StyledCompanyBadge>
            {joinDialogData?.company_logo && <img src={joinDialogData?.company_logo} alt={joinDialogData?.company_name} />}
            <Typography variant="labelXS">{joinDialogData?.company_name}</Typography>
          </StyledCompanyBadge>
          <Typography variant="body1" sx={{ marginBottom: "8px !important" }}>
            {joinDialogData?.inviter_name} invited you to join the {joinDialogData?.company_name} workspace !
          </Typography>
          <Typography variant="subtitle1">
            You&rsquo;ve been invited by <span style={{ fontWeight: "600", color: theme.palette.font.main }}>{joinDialogData?.inviter_name}</span> to
            join the {joinDialogData?.company_name} workspace. Would you like to join now or cancel the invitation.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", gap: "16px !important" }}>
          <Button
            onClick={() => handleJoinCancelWorkspace("accepted")}
            fullWidth
            title={`Join ${joinDialogData?.company_name}`}
            variant="neutral"
            state="Linear"
            img={joinDialogData?.company_logo}
          />
          <Button onClick={() => handleJoinCancelWorkspace("rejected")} fullWidth title="Cancel" variant="neutral" state="Stroke" />
        </DialogActions>
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={isDemoAccountDialogOpen} className="demo-dialog">
        <IconButton onClick={() => setIsDemoAccountDialogOpen(false)}>
          <RiCloseLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
        </IconButton>
        <DialogContent sx={{ borderBottom: "0 !important", zIndex: 2 }}>
          <img src={DemoLockImage} alt="demo-lock" />
          <Typography variant="labelLG">You&rsquo;re currently using a Demo Account.</Typography>
          <Typography variant="paragraphSM">
            You are using a demo account to explore the platform. To create your own company, click the “Book a Call” button for assistance with your
            first account setup!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Link to="https://cal.com/huseyin-ss/auma-intro" target="_blank" style={{ width: "100%" }}>
            <Button fullWidth title="Book a call" variant="primary" state="Filled" />
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MainLayout;
