import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  calUser: null,
  accessToken: "",
};

const calComSlice = createSlice({
  name: "calCom",
  initialState,
  reducers: {
    calComUserSuccess(state, action) {
      const { user } = action.payload;
      if (user && user.data) {
        state.calUser = user.data;
        state.accessToken = user.data.access_token;
      }
      state.loading = false;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const { loadingSuccess, calComUserSuccess } = calComSlice.actions;

export default calComSlice.reducer;
