export const nodeEnv = process.env.REACT_APP_AUMA_ENV;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;
export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
export const INTERCOM_KEY = process.env.REACT_APP_INTERCOM_KEY;
export const FE_URL = process.env.REACT_APP_FE_URL;
export const FE_APPLY_URL = process.env.REACT_APP_FE_APPLY_URL;
export const CAL_OAUTH_CLIENT_ID = process.env.REACT_APP_CAL_OAUTH_CLIENT_ID;
export const CAL_API_URL = process.env.REACT_APP_CAL_API_URL;
